import { useAuth0 as useAuth0Core } from '@auth0/auth0-react'

const NAMESPACE = 'https://idx.klick.com/'

export const useAuth0 = () => {
  const { user: rawUser, ...rest } = useAuth0Core()
  const user = rawUser && normalizeNamespacedProperties(rawUser)
  return { user, ...rest }
}

const normalizeNamespacedProperties = (obj) =>
  Object.keys(obj).reduce((acc, rawKey) => {
    const key = rawKey.startsWith(NAMESPACE)
      ? rawKey.replace(NAMESPACE, '')
      : rawKey
    const value = obj[rawKey]
    return { ...acc, [key]: value }
  }, [])
