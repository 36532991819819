import React from 'react'
import { Helmet } from 'react-helmet'
import { HS_EVENTS, tracking } from '@klickmarketing/react-components'

import { useAuth0 } from '../hooks/useAuth0Klick'

export default function LoginSuccess() {
  const { isAuthenticated } = useAuth0()

  React.useEffect(() => {
    if (isAuthenticated) {
      tracking.track('Log in', {
        category: 'Page Interactions',
        action: 'Log in',
        label: 'IDX - Log in',
        hsPayload: { name: HS_EVENTS.USER_LOGGED_IN, properties: {} },
      })
    }
  }, [isAuthenticated])

  return (
    <Helmet>
      <meta name="robots" content="noindex,follow" />
    </Helmet>
  )
}
